export default class FromThrift {
  static getInput(atom) {
    const delimiter = "\t";

    const {headline, source, standfirst} = atom.data.chart.furniture;
    const {tabularData} = atom.data.chart;

    const rows = tabularData.rowHeaders.reduce((acc, rowHeader, index) => {
      return [...acc, [rowHeader, ...tabularData.rowData[index]].join(delimiter)];
    }, []);

    const lines = [
      ["headline", headline].join(delimiter),
      ["source", source].join(delimiter),
      ["standfirst", standfirst].join(delimiter),
      tabularData.columnHeaders.join(delimiter),
      ...rows
    ];

    return lines.join("\n");
  }

  static getShow(atom) {
    const {rowHeaders, columnHeaders} = atom.data.chart.tabularData;
    const {hiddenRows, hiddenColumns} = atom.data.chart;

    const rows = rowHeaders.map(() => true);
    hiddenRows.forEach(i => rows[i] = false);

    const cols = columnHeaders.map(() => true);
    hiddenColumns.forEach(i => cols[i] = false);

    return {
      row: [...rows, true], // add an item for the column headers row
      col: cols
    };
  }
}
