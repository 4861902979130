import React from "react";
import {connect} from "react-redux";
import "./section4Panel.css";
import {ratio} from "../data/config";
import {chartList} from "./charts";
import Config from "../lib/config";
import { getAxisProperty, getMetaText } from "./section4Panel/helpers";

import ComponentSize       from "./section4Panel/Size";
import ComponentResponsive from "./section4Panel/Responsive";
import ComponentPalette    from "./section4Panel/Palette";
import ComponentDisplay    from "./section4Panel/Display";
import ComponentEditor     from "./section4Panel/Editor";
import ComponentLegend     from "./section4Panel/Legend";
import ComponentXLabel     from "./section4Panel/LabelX";
import ComponentSetAxis    from "./section4Panel/SetAxis";
import ComponentXAxis      from "./section4Panel/AxisX";
import ComponentYAxis      from "./section4Panel/AxisY";
import responsiveXTexts    from "./section4Panel/axisXTextAndSvgResponsive";
import responsiveXLabel    from "./section4Panel/axisXLabelResponsive";
import responsiveYTexts    from "./section4Panel/axisYTextResponsive";

const STEP = 4;

const mapStateToProps = (state) => ({
  id: state.chartId,
  step: state.step,
  stepActive: state.stepActive,
  chartId: state.chartId,
  chartData: state.dataChart,
  metaData: state.dataTable.meta,
  display: state.dataSetup.display,
  graphWidth: state.dataSetup.width,
  axis: state.dataEditable.axis
});

const mapDispatchToProps = () => ({
});


class Section extends React.Component {

  componentDidUpdate() {
    const {stepActive, chartId, chartData} = this.props;
    if (stepActive < STEP) return;

    /* chart axes responsive */
    // x-text-top/bottom (with ticks), y-text (with ticks), and y-label's posiitons
    // due to tick or text/label editing
    if (chartData.scales.x || chartId.includes("col")) {
      responsiveYTexts(chartData.string1Width);
      responsiveXTexts();
      responsiveXLabel();
    }
  }

  render() {

    const {stepActive, chartId, graphWidth, chartData, display, axis} = this.props;

    const isOnBar = chartId.includes("onBar");
    const isBarBased = chartId.toLowerCase().includes("bar");
    const isPlot = chartId.toLowerCase().includes("plot");
    const isCol = chartId.toLowerCase().includes("col");
    const isLine = chartId.toLowerCase().includes("line");

    const metaData = this.props.metaData;
    const ComponentChart = chartList[chartId];
    const chartComponent = ComponentChart && (
      <div
        data-id={chartId} data-res-y={chartData.string1IsRes && isBarBased} className="chart js-chart" style={{
          marginTop: isBarBased || isLine ? "24px" : 0,
          marginBottom: isBarBased ? 0 : "30px",
          paddingBottom: isBarBased ? "1px" : (ratio*100) + "%",
          // 1px for barBasaed to keep chart's height
        }}>
        <ComponentYAxis />
        <ComponentXAxis isBarBased={isBarBased} isOnBar={isOnBar} isPlot={isPlot}/>
        <ComponentChart id={chartId+"_edit"} callByStep={STEP} />
        {isCol && <ComponentXLabel /> }

        {/* hack to allow changes to ticks in atom workshop to be saved - remove after amending content-atom model*/}
        {
          Config.isEmbedded &&
            <div style={{display: "none"}} id="state-holder"
              data-x-ticks={ getAxisProperty(axis, "x", "ticks") }
              data-y-ticks={ getAxisProperty(axis, "y", "ticks") }>
            </div>
        }
      </div> );

    const graphComponent = stepActive >= STEP && (
      <div className="graph js-graph" style={{width: graphWidth}}>
        {/* header */}
        <header className="header">
          <div className={"headline" + (display["headline"] ? "" : " d-n")} >
            <ComponentEditor type="headline" text={getMetaText("headline", metaData)} bold={true} />
          </div>
          <div className={"standfirst" + (display["standfirst"] ? "" : " d-n")}>
            <ComponentEditor type="standfirst" text={getMetaText("standfirst", metaData)} />
          </div>
          <ComponentLegend isBarBased={isBarBased} text={getMetaText("legend", metaData)}/>
        </header>
        {/* main: graph / chart */}
        {chartComponent}
        {/* footer */}
        <footer className={display["source"] ? "" : " d-n"}>
          <ComponentEditor type="source" text={getMetaText("source", metaData)} />
        </footer>
        <span className="test js-test-res"></span>
      </div>
    );

    const setupComponent = stepActive >= STEP &&(
      <div className="setup row-flex">
        <div className="setup-p1">
          <ComponentSize />
          <ComponentResponsive />
          <ComponentPalette />
          <ComponentDisplay />
        </div>
        <div className="setup-p2">
          <ComponentSetAxis type="x"/>
          <ComponentSetAxis type="y"/>
        </div>
      </div>
    );

    return (
      <div className={"section" + ((stepActive>=STEP) ? "" : " d-n")} id="section4">
        <h1>4. Edit your graph</h1>
        {setupComponent}
        {graphComponent}
        {/* any styles inside graph needs to be either included in the template.js or inline */}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Section);
