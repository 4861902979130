import React from "react";
import {connect} from "react-redux";
import "./section1Input.css";
import {importData, clearData} from "../actions";
import AtomBanner from "./AtomBanner";
import Config from "../lib/config";
import data from "../exampleData.json";

//const STEP = 1;
// read https://facebook.github.io/react/docs/forms.html
const instruction = "Type your data or copy & paste it from a spreadsheet.";

const mapStateToProps = (state) => ({
  atomInput: state.atomInput
});

const mapDispatchToProps = (dispatch) => ({
  onClickImport: (textarea) => {
    dispatch(importData(textarea.value));
  },
  onClickClear: (textarea/*, textInput*/) => {
    //[textarea, textInput].forEach(input => input.value= "")
    textarea.value= "";
    dispatch(clearData());
  }
});

class Section extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value: props.atomInput
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.atomInput !== prevProps.atomInput) {
      this.setState({
        value: this.props.atomInput
      });
    }
  }

  handleChange(event) {
    this.setState({
      value: event.target.value
    });
  }

  populateSampleData(type) {
    const textarea = document.getElementsByClassName("textarea")[0];
    textarea.value = data.data[type];
  }

  buttonsForSampleData() {
    return Object.keys(data.data).map(graph => (
      <button
        key={data.data[graph]}
        onClick={() => this.populateSampleData(graph)}
      >
        {graph}
      </button>
    ));
  }

  render() {
    let textarea, textInput;

    const { onClickImport, onClickClear} = this.props;

    return (
      <div className="section" id="section1" ref={(node) => this.node = node}>
        {Config.isEmbedded ? "": <AtomBanner/>}
        <h1>1. Import data </h1>
        <p className="instruction">Instruction: {instruction}</p>
        {Config.isDev ? (
          <p className="instruction">
Sample data: {this.buttonsForSampleData()}</p>
        ) : (
          ""
        )}
        <textarea className="textarea" placeholder="your csv/tsv dataset goes here ..." ref={node=>textarea=node} value={this.state.value || ""} onChange={e => this.handleChange(e)}></textarea>
        <div className="row-flex">
          <input type="button" className="button btn-import" value="Import" onClick={()=>onClickImport(textarea)}/>
          <input type="button" className="button btn-clear" value="Clear" onClick={()=>onClickClear(textarea, textInput)}/>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Section);
