import configFile from "../assets/data/config.json";

export const ratio = 0.6;
export const width = 300;
export const height = width * ratio;
export const viewBox = "0 0 " + width + " " + height;

export let cfg_charts;
export let colors, colorBarBackground;
export let metaKeys, default_metaText;
export let chartNames = {};

/** use the config.json file to populate the values for exported variable:
 *  - cfg_charts
 *  - colors
 *  - colorBarBackground
 *  - metaKeys
 *  - default_metaText
 *  - chartNames
 */
export default function config() {
  parseCfgJson(configFile);
  return Promise.resolve();
}

function parseCfgJson(cfg) {

  /* colors: guardian's pre-defined colors */
  colors = cfg.COLORS
    .filter(c => c.type === "all")
    .sort((c1, c2) => c1.order - c2.order).map(c => c.code);

  colorBarBackground = cfg.COLORS
    .find(c => c.type === "barBackground").code || "#f1f1f1";

  // TODO: add map colors, see mapData.js
  // ...

  /* charts: cfg for chart selection
  // TODO: sort by order and filter by on/off flag */
  cfg_charts = cfg.CFG_CHARTS
    .filter(chart => JSON.parse(chart.active))
    .map(chart => {

      // 1. count => 5 features:
      // row, date, number, string1, string2
      let count = {
        row: JSON.parse(chart.row.replace("Infinity", "\"Infinity\""))
      };
      ["date", "number", "string1", "string2"].forEach(feature => {
        count[feature] = JSON.parse(chart[feature]);
      });

      // 2. value => 5 features:
      // date_hasRepeat, number_hasNull, number_rangeType, numberH_format, string1_hasRepeat
      const value = {
        numberH_format: chart.numberH_format.trim() !== "" ? chart.numberH_format : null
        //string1_format: chart.string1_format.trim() !== "" ? chart.string1_format : null
      };
      ["date_hasRepeat", "string1_hasRepeat", "number_hasNull", "number_rangeType"].forEach(feature => {
        value[feature] = chart[feature].trim() !== "" ? JSON.parse(chart[feature]) : null;
      });

      return {
        chart: chart.id,
        count,
        value
      };
    });

  cfg.CFG_CHART_DEF
    .filter(chart => chart.num)
    .forEach(chart => chartNames[chart.id] = chart.name);

  //console.log(colors)
  //console.log(cfg_charts)
  //console.log(chartNames)
}

// TODO: add meta to config file ?
metaKeys = ["headline", "standfirst", "legend", "source"]; // note, ...
default_metaText = {
  "headline": "Headline ...",
  "standfirst": "Standfirst ...",
  "legend":     "No keys ...",
  "source":     "Guardian graphic | Source"
};
