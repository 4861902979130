import reqwest from "reqwest";
import Config from "../config";

export default class AtomApi {
  static updateAtom(atomId, atom, defaultHtml) {
    return reqwest({
      url: `${Config.atomWorkshopDomain}/api/preview/chart/${atomId}`,
      method: "PUT",
      contentType: "application/json",
      crossOrigin: true,
      withCredentials: true,
      data: JSON.stringify({
        ...atom,
        contentChangeDetails: {
          revision: atom.contentChangeDetails.revision + 1
        },
        defaultHtml
      })
    });
  }

  static getAtom(atomId) {
    return reqwest({
      url: `${Config.atomWorkshopDomain}/api/preview/chart/${atomId}`,
      method: "GET",
      crossOrigin: true,
      withCredentials: true
    }).then(atom => {
      return atom;
    });
  }
}
