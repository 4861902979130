function getRowHeadersType(value) {
  if (value === "string1") {
    return "string";
  }

  return value;
}

function removeCommaFromString(numberAsString) {
  return numberAsString && numberAsString.replace(",","");
}

function datatableToAtomTabularData(datatable) {
  const rows = datatable.body.reduce((acc, item) => {
    const [header, ...data] = item;

    return {
      rowHeaders: [...acc.rowHeaders, header],
      rowData: [...acc.rowData, data.map(d => {
        return parseFloat(removeCommaFromString(d));
      })]
    };
  }, {
    rowHeaders: [],
    rowData: []
  });

  return Object.assign({}, rows, {
    rowHeadersType: getRowHeadersType(datatable.type[0].list[0]),
    columnHeaders: datatable.head,
  });
}

function getHiddenItems(rowOrColArray) {
  return rowOrColArray.reduce((acc, item, index) => {
    return item ? acc : [...acc, index];
  }, []);
}


export default class ToThrift {
  static getTabularData(datatable) {
    return datatableToAtomTabularData(datatable);
  }

  static getFurniture(datatable) {
    return datatable.meta;
  }

  static getHiddenColumns(show) {
    return getHiddenItems(show.col);
  }

  static getHiddenRows(show) {
    return getHiddenItems(show.row);
  }

  static displayFieldToDisplaySetting(field) {
    // Gross! Convert standFirst to showStandfirst
    return `show${field.charAt(0).toUpperCase()}${field.slice(1).toLowerCase()}`;
  }

  static getDisplaySettings(display) {
    return Object.keys(display).reduce((acc, key) => {
      acc[ToThrift.displayFieldToDisplaySetting(key)] = display[key];
      return acc;
    }, {});
  }
}
