import React from "react";
import { connect } from "react-redux";
import "./section3Chart.css";
import {colors, metaKeys, chartNames} from "../data/config";
import {selectChart, setColors, setDisplay} from "../actions";
import {updateAtomChartType} from "../actions/atomActions";
import { chartList, atomChartList } from "./charts";
import Config from "../lib/config";
import {getAtomProperty} from "../lib/atom/helpers";

const STEP = 3;
const instruction = "Click on a visualization for editing.";
const messageError = `
  Oh, oh, something went wrong! You could:
  1. Double check your dataset,
  2. Contact the Guardian's Editorial Tools team at editorial.tools.dev@guardian.co.uk
  `;

const mapStateToProps = (state) => ({
  step: state.step,
  stepActive: state.stepActive,
  selection: state.selection,
  dataMeta: state.dataTable.meta,
  atom: state.atom
});

const mapDispatchToProps = (dispatch) => ({
  onSelect: (chartId) => {
    dispatch(selectChart(chartId));
    dispatch(updateAtomChartType(chartId));
  },
  setDefaultColors: (colors) => dispatch(setColors(colors)),
  setDefaultDisplay: (display) => dispatch(setDisplay(display))
});

class Section extends React.Component {

  componentWillUpdate() {
    const {step, dataMeta, setDefaultColors, setDefaultDisplay, atom} = this.props;

    // setup1 palette colors
    if (step === 2) {
      setDefaultColors(colors);
    }
    // setup1 display controls
    if (step === 3) {

      const atomDisplaySettings = getAtomProperty(atom, "displaySettings");

      if(atomDisplaySettings) {
        setDefaultDisplay( this.cleanAtomDisplaySettings(atomDisplaySettings));
      }
      else {
        const display = {};
        metaKeys.forEach(key => {
          display[key] = (key === "standfirst" && !dataMeta[key]) ? false : true;
        });
        setDefaultDisplay(display);
      }
    }
  }

  cleanAtomDisplaySettings(displayFromAtom){
    return Object.entries(displayFromAtom).reduce((acc, curr) => {
      let newKey = curr[0].replace("show", "").toLowerCase();
      acc[newKey] = curr[1];
      return acc;
    }, {});
  }

  getSupportedCharts(atom){
    if(Config.isEmbedded){

      if(getAtomProperty(atom, "hasUserSelectedChartType")) {
        return Array(getAtomProperty(atom, "chartType"));
      }
      else {
        return Object.keys(atomChartList);
      }
    } else {
      return Object.keys(chartList);
    }
    // returns array of strings ["bars", "lineDiscrete"] etc
  }

  getChartTemplateKey(id, chartObj){
    return Object.keys(chartObj).find(key => key.toLowerCase() === id);
  }

  render() {
    const { stepActive, selection, onSelect, atom } = this.props;

    const supportedCharts = this.getSupportedCharts(atom);
    const selectionLowerCase = selection.map(sel => sel.toLowerCase());

    const chartComponents = supportedCharts.map(chartID => {
      const chartIDLowerC = chartID.toLowerCase();
      const isSelected = selectionLowerCase.includes(chartIDLowerC);
      const chartKey = this.getChartTemplateKey(chartIDLowerC, chartList);
      const ComponentChart = chartList[chartKey];

      return isSelected
        ? (
          <div key={chartKey} id={chartKey} onClick={() => onSelect(chartKey)}>
            <ComponentChart id={chartKey} callByStep={STEP} />{chartNames[chartKey]}
          </div>
        )
        : null;
    });


    const availableChartsSuitableForData = supportedCharts.filter(supportedChart => selectionLowerCase.includes(supportedChart.toLowerCase()));

    return (
      <div className={"section" + ((stepActive >= STEP) ? "" : " d-n")} id="section3">
        <h1>3. Select a visualization</h1>
        <p className="instruction">
          Instruction: {availableChartsSuitableForData.length > 0 ? instruction : messageError}
        </p>
        <div className="charts">
          {availableChartsSuitableForData.length > 0 ? chartComponents : "There is NO RESULT!!"}
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Section);
