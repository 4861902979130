import React from "react";
import { connect } from "react-redux";
import ComponentEditor from "./Editor";
import {getTicks, formatTicks} from "./helpers";

const mapStateToProps = state => ({
  id: state.chartId,
  step: state.step,
  dataEditable: state.dataEditable,
  atom: state.atom,
  dataChart: state.dataChart
});


class SetAxis extends React.Component {
  render() {
    const { id, step, type, dataEditable, atom, dataChart} = this.props;

    if (step !== 4 || !dataEditable.axis) {
      return null;
    }

    const hasEditorAxisT = {
      x: id.indexOf("col") === -1 && id.indexOf("slope") === -1,
      y: id.toLowerCase().indexOf("bar") === -1 && id.indexOf("slope") === -1
    };
    const hasEditorRange = {
      x: id.indexOf("plot") > -1 || id.indexOf("onBar") > -1,
      y: id.indexOf("plot") > -1 || id.indexOf("line") > -1
    };
    if (!hasEditorAxisT && !hasEditorRange) {
      return null;
    }

    const defaultAxis = dataEditable.axis[type];
    const ticksToUse = getTicks(atom, defaultAxis, type);

    let formattedTicks;

    // TODO: revisit the creation of formatted ticks - the x-axis is not guaranteed to be a date
    if(type === "x") {
      formattedTicks = formatTicks(dataChart.dateFormat, ticksToUse);
    }
    else {
      formattedTicks = ticksToUse.join(", ");
    }

    const rangeToUse = defaultAxis.edits ? defaultAxis.edits : defaultAxis;
    const range = rangeToUse.range.join(", ");

    const editorRange = (
      <span className={"ws-n" + (hasEditorRange[type] ? "" : " o-disable")}>
        <span className="mr-10">Range:</span>[
        {hasEditorRange[type] ? (
          <ComponentEditor text={range} type={type + "Range"} />
        ) : (
          range
        )}
        ]
      </span>
    );

    return hasEditorAxisT[type] ? (
      <div>
        <span className="ws-pl">
          <span className="mr-10">{type.toUpperCase()}-Ticks:</span>
          <ComponentEditor text={formattedTicks} type={type + "Ticks"} />
          <span>; </span>
        </span>
        {editorRange}
      </div>
    ) : null;
  }
}

export default connect(mapStateToProps)(SetAxis);
