import Config from "../lib/config";


// update should be done via redux / state

export const updateScreen = (msg, url) => {
  let elLink = document.querySelector(".js-link");
  let elLinkA = elLink.querySelector("a");
  let elLinkMsg = elLink.querySelector("#embed-message");

  elLinkA.href = url;
  elLinkA.textContent = url;
  elLinkMsg.textContent = msg;
};

export const postToS3Uploader = data => {
  const s3UploadUrl = `${Config.s3UploaderDomain}/upload-chart`;

  // converting to the FormData format to make it easier to generate a file in the S3 uploader
  const blob = new Blob([data.html], { type: "text/html" });
  let formData = new FormData();
  formData.append("blob", blob, "chartFile");

  let xhr = new XMLHttpRequest();

  xhr.onloadstart = () => {
    updateScreen(
      "Loading embed link...",
      ""
    );
  };

  xhr.onloadend = () => {
    console.log("status in onload", xhr.status);

    if(xhr.status === 200) {
      const res = JSON.parse(xhr.response);
      updateScreen(
        "Embed link: ",
        res.url
      );
    }
    else if(xhr.status === 401 || xhr.status === 403) {
      updateScreen(
        "Authentication error: please login at this link, then try to save again: ",
        Config.loginDomain
      );
    }
    else {
      updateScreen(
        "Something went wrong uploading chart to s3. Try again or contact editorial.tools.dev@guardian.co.uk",
        ""
      );
    }

  };

  xhr.open("POST", s3UploadUrl);
  xhr.withCredentials = true;
  xhr.setRequestHeader("SameSite", "None");
  xhr.send(formData);


};
