import React from "react";
import { render } from "react-dom";
import { Provider } from "react-redux";
import { compose, createStore, applyMiddleware } from "redux";
import thunkMiddleware from "redux-thunk";
//import logger from "redux-logger"
import "./index.css";
//import '../bower_components/guss-typography/_typography.scss'
import reducers from "./reducers";
import App from "./App";
import Config from "./lib/config";
import { loadAtom } from "./actions/atomActions";
import fetchConfig from "./data/config";

const middlewares = [applyMiddleware(thunkMiddleware)];

if (window.__REDUX_DEVTOOLS_EXTENSION__) {
  middlewares.push(window.__REDUX_DEVTOOLS_EXTENSION__());
}

//const middleware = applyMiddleware(logger())
const store = createStore(reducers, compose(...middlewares));

fetchConfig().then(() => {
  if (Config.embeddedAtomId) {
    store.dispatch(loadAtom(Config.embeddedAtomId));
  }

  /* This is a workaround to ensure that users have the appropriate cookie
     needed for the charts tool to communicate with the s3-uploader.
     This is not necessary when accessing the tool through Atom Workshop */
  const notLoggedIn = !sessionStorage.getItem("loggedIn");
  if (notLoggedIn && !Config.isEmbedded && Config.isProd) {
    sessionStorage.setItem("loggedIn", true);
    window.location = Config.loginDomain;
  }

  render(
    <Provider store={store}>
      <App />
    </Provider>,
    document.getElementById("root")
  );
});
