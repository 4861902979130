import LineDiscrete from "./charts/LineDiscrete";
import LineContinue from "./charts/LineContinue";
import PlotDot from "./charts/PlotDot";
import PlotScatter from "./charts/PlotScatter";
import Slopegraph from "./charts/Slopegraph";
import Bars from "./charts/Bars";
import BarStack from "./charts/BarStack";
import BarStack100 from "./charts/BarStack100";
import Bars100 from "./charts/Bars100";
import BrokenBar100 from "./charts/BrokenBar100";
import OnBarArrow from "./charts/OnBarArrow";
import OnBarDots from "./charts/OnBarDots";
import OnBarTicks from "./charts/OnBarTicks";
import Cols from "./charts/Cols";
import ColStack from "./charts/ColStack";
import AreaStack from "./charts/AreaStack";
//import ColHistogram
//import ColMatrix
//import LineContiWithPlot  from './charts/LineContiWithPlot'
//import MapChoropleth      from './charts/MapChoropleth'
//import MapBubble          from './charts/MapBubble'
//import Donut              from './charts/Donut'
//import Treemap

// TODO: obj -> arr, sort by config file
export const chartList = {
  lineDiscrete: LineDiscrete,
  line: LineContinue,
  plotDot: PlotDot,
  plotScatter: PlotScatter,
  onBarDiffArrow: OnBarArrow,
  onBarDiffDots: OnBarDots,
  onBarTicks: OnBarTicks,
  bar: Bars,
  barGroup: Bars,
  barStack: BarStack,
  barStack100: BarStack100,
  bar100: Bars100,
  brokenBar100: BrokenBar100,
  col: Cols,
  colGroup: Cols,
  colStack: ColStack,
  colGroupStack100: AreaStack,
  slopegraph: Slopegraph,
  //areaGroupStack: AreaStack,
  //donut:          Donut,
  //mapChoropleth:  MapChoropleth,
  //mapBubble:      MapBubble
};

// A limited subset of charts that can currently be saved as atoms and the d3 template that they map to
export const atomChartList = {
  bar: Bars,
  lineDiscrete: LineDiscrete,
  line: LineContinue,
  col: Cols,
  barStack: BarStack,
  colStack: ColStack,
  barStack100: BarStack100,
};
