import ToThrift from "../lib/atom/toThrift";
import AtomApi from "../lib/atom/api";
import FromThrift from "../lib/atom/fromThrift";
import { importData, analyzeData } from "./index";
import parseDataInput from "../data/parseDataInput";
import { default_metaText } from "../data/config";
import {parseHtml} from "../data/parseChartHtml";

export const UPDATE_ATOM_TABULAR_DATA = "UPDATE_ATOM_TABULAR_DATA";
export function updateAtomTabularData(dataTable) {
  return {
    type: UPDATE_ATOM_TABULAR_DATA,
    data: {
      chart: {
        tabularData: ToThrift.getTabularData(dataTable)
      }
    }
  };
}

export const UPDATE_ATOM_FURNITURE = "UPDATE_ATOM_FURNITURE";
export function updateAtomFurniture(datatable) {
  return {
    type: UPDATE_ATOM_FURNITURE,
    data: {
      chart: {
        furniture: {
          headline: default_metaText.headline,
          source: default_metaText.source || "Guardian graphic",
          ...ToThrift.getFurniture(datatable)
        }
      }
    }
  };
}

export const UPDATE_ATOM_HIDDEN_COLUMNS = "UPDATE_ATOM_HIDDEN_COLUMNS";
export function updateAtomHiddenColumns(show) {
  return {
    type: UPDATE_ATOM_HIDDEN_COLUMNS,
    data: {
      chart: {
        hiddenColumns: ToThrift.getHiddenColumns(show)
      }
    }
  };
}

export const UPDATE_ATOM_HIDDEN_ROWS = "UPDATE_ATOM_HIDDEN_ROWS";
export function updateAtomHiddenRows(show) {
  return {
    type: UPDATE_ATOM_HIDDEN_ROWS,
    data: {
      chart: {
        hiddenRows: ToThrift.getHiddenRows(show)
      }
    }
  };
}

export const UPDATE_ATOM_CHART_TYPE = "UPDATE_ATOM_CHART_TYPE";
export function updateAtomChartType(chartType) {
  return {
    type: UPDATE_ATOM_CHART_TYPE,
    data: {
      chart: {
        chartType
      }
    }
  };
}

export const UPDATE_ATOM_DISPLAY_SETTINGS = "UPDATE_ATOM_DISPLAY_SETTINGS";
export function updateAtomDisplaySettings(display) {
  return {
    type: UPDATE_ATOM_DISPLAY_SETTINGS,
    data: {
      chart: {
        displaySettings: ToThrift.getDisplaySettings(display)
      }
    }
  };
}

export const UPDATE_ATOM_DISPLAY_SETTING = "UPDATE_ATOM_DISPLAY_SETTING";
export function updateAtomDisplaySetting(field) {
  return {
    type: UPDATE_ATOM_DISPLAY_SETTING,
    field: ToThrift.displayFieldToDisplaySetting(field)
  };
}

export const UPDATE_ATOM_HEADLINE = "UPDATE_ATOM_HEADLINE";
export function updateAtomHeadline(headline) {
  return {
    type: UPDATE_ATOM_HEADLINE,
    headline
  };
}

export const UPDATE_ATOM_LEGEND = "UPDATE_ATOM_LEGEND";
export function updateAtomLegend(index, value) {
  return {
    type: UPDATE_ATOM_LEGEND,
    index,
    value
  };
}

export const UPDATE_ATOM_SOURCE = "UPDATE_ATOM_SOURCE";
export function updateAtomSource(source) {
  return {
    type: UPDATE_ATOM_SOURCE,
    source
  };
}

export const UPDATE_ATOM_STANDFIRST = "UPDATE_ATOM_STANDFIRST";
export function updateAtomStandfirst(standfirst) {
  return {
    type: UPDATE_ATOM_STANDFIRST,
    standfirst
  };
}

export const UPDATE_ATOM_COLORS = "UPDATE_ATOM_COLORS";
export function updateAtomColors(index, hexCode) {
  return {
    type: UPDATE_ATOM_COLORS,
    color: {
      index,
      hexCode
    }
  };
}

export const ATOM_GET_REQUEST = "ATOM_GET_REQUEST";
function requestAtom(atomId) {
  return {
    type: ATOM_GET_REQUEST,
    atomId
  };
}

export const ATOM_GET_RECEIVED = "ATOM_GET_RECEIVED";
function receiveAtom(atomId, atom) {
  return {
    type: ATOM_GET_RECEIVED,
    atomId,
    atom
  };
}

export const LOAD_INPUT_FROM_ATOM = "LOAD_INPUT_FROM_ATOM";
function loadInputFromAtom(input) {
  return {
    type: LOAD_INPUT_FROM_ATOM,
    atomInput: input
  };
}

export const LOAD_SHOW_FROM_ATOM = "LOAD_SHOW_FROM_ATOM";
function loadShowFromAtom(show) {
  return {
    type: LOAD_SHOW_FROM_ATOM,
    show
  };
}

export const IMPORT_CHART_COLORS = "IMPORT_CHART_COLORS";
function importChartColors(chartColors) {
  return {
    type: IMPORT_CHART_COLORS,
    chartColors
  };
}

export const IMPORT_LABEL_STYLING = "IMPORT_LABEL_STYLING";
function importLabelStyling(labelStyles) {
  return {
    type: IMPORT_LABEL_STYLING,
    labelStyles
  };
}

export const SET_ATOM_AXIS_TICKS = "SET_ATOM_AXIS_TICKS";
export function setAtomAxisTicks(ticks) {
  return {
    type: SET_ATOM_AXIS_TICKS,
    ticks
  };
}


export const SET_ATOM_HAS_BEEN_EDITED = "SET_ATOM_HAS_BEEN_EDITED";
export function setAtomHasBeenEditedFlag(bool){
  return {
    type: SET_ATOM_HAS_BEEN_EDITED,
    hasUserSelectedChartType: bool
  };
}

export function loadAtom(atomId) {
  return dispatch => {
    dispatch(requestAtom(atomId));
    return AtomApi.getAtom(atomId).then(atom => {
      dispatch(receiveAtom(atomId, atom));

      const input = FromThrift.getInput(atom);
      const datatable = parseDataInput(input);
      const show = FromThrift.getShow(atom);

      const chartType = atom.data.chart.chartType;
      const chartDisplay = parseHtml(chartType, atom.defaultHtml);

      dispatch(loadInputFromAtom(input));
      dispatch(importData(input));
      dispatch(loadShowFromAtom(show));
      dispatch(analyzeData(datatable, show));
      dispatch(importChartColors(chartDisplay.colors));
      dispatch(importLabelStyling(chartDisplay.labelStyling));
      dispatch(setAtomAxisTicks(chartDisplay.axes));
      dispatch(setAtomHasBeenEditedFlag(true)); //always true when fetching atom
    });
  };
}
