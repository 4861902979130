import React from "react";
import { connect } from "react-redux";
import "./editor.css";
import { d3 } from "../../lib/d3-lite";
import { uniqueArray } from "../../lib/array.js";
import {
  updateAxisYLabelRes,
  updateAxisYLabelChange,
  appendAxisYScaleRes,
  updateAxisDataOnTypes,
  updateScaleRange
} from "../../actions";
import {
  updateAtomHeadline,
  updateAtomLegend,
  updateAtomSource,
  updateAtomStandfirst,
  setAtomAxisTicks
} from "../../actions/atomActions";
import { getDateScaleValues } from "../../data/typeDate";
import {
  getAxisYLabelRes,
  getAxisYTextWidth
} from "../../data/calcAxisYText";
import responsiveXTexts from "./axisXTextAndSvgResponsive";
import responsiveXLabel from "./axisXLabelResponsive";

/* editor ref: draftjs.org/docs
/* example: https://github.com/facebook/draft-js/blob/master/examples/draft-0-10-0/convertFromHTML/convert.html */
/* In this editor, there are 3 cases to trigger content update */
import {
  Editor,
  EditorState,
  ContentState,
  convertFromHTML,
  RichUtils,
  Modifier
} from "draft-js";

const mapStateToProps = state => ({
  chartId: state.chartId,
  yIndent: state.dataChart.indent,
  dataRanges: state.dataChart.ranges,
  dateFormat: state.dataChart.dateFormat,
  dateHasDay: state.dataChart.dateHasDay,
  labels: state.dataChart.rowGroup,
  axis: state.dataEditable.axis
});
const mapDispatchToProps = dispatch => ({
  setAxisYLabelRes: (isRes, width) =>
    dispatch(updateAxisYLabelRes(isRes, width)),
  setAxisYLabelChange: dataChange =>
    dispatch(updateAxisYLabelChange(dataChange)),
  setAxisYScale: indent => dispatch(appendAxisYScaleRes(indent)),
  setAxisDataOnTypes: (target1, target2, dataTarget, dataTargetExtra) =>
    dispatch(
      updateAxisDataOnTypes(target1, target2, dataTarget, dataTargetExtra)
    ),
  setAtomAxisData: (atomTicks) => dispatch(setAtomAxisTicks(atomTicks)),
  setAxisScaleRange: (target, range) =>
    dispatch(updateScaleRange(target, range)),
  editHeadline: value => dispatch(updateAtomHeadline(value)),
  editLegend: (index, value) => {
    dispatch(updateAtomLegend(index, value));
  },
  editSource: value => dispatch(updateAtomSource(value)),
  editStandfirst: value => dispatch(updateAtomStandfirst(value))
});

const addStyleToText = (text, styles) => {
  const { bold, italic } = styles;
  const maybeBoldedText = bold ? "<b>" + text + "</b>" : text;
  return italic ? "<em>" + maybeBoldedText + "</em>" : maybeBoldedText;
};

class InlineEditor extends React.Component {
  createEditorState(text) {
    const blockArray = convertFromHTML(addStyleToText(text, this.props));
    const contentState = ContentState.createFromBlockArray(
      blockArray.contentBlocks,
      blockArray.entityMap
    );
    return EditorState.createWithContent(contentState);
  }

  modifyContentStateReplace(
    editorState,
    contentState,
    prevText,
    nextText,
    style = ""
  ) {
    // ref: https://github.com/react-component/editor-mention/blob/db5cfa300ebc773f70e304c46c7b9a0c48b5f00d/src/utils/insertMention.jsx
    return Modifier.replaceText(
      contentState,
      editorState.getSelection().merge({
        anchorOffset: 0,
        focusOffset: prevText.length
      }),
      nextText,
      [nextText, style]
    );
  }

  constructor(props) {
    super(props);

    // init editorState
    const editorState = this.createEditorState(props.text);
    this.state = { editorState: editorState };
  }

  componentDidMount() {
    this.onBlur();
  }

  updateFurniture(type, content) {
    const {editHeadline, editLegend, editSource, editStandfirst, legendIndex} = this.props;
    switch (type) {
    case "headline": {
      editHeadline(content);
      break;
    }
    case "legend": {
      editLegend(legendIndex, content);
      break;
    }
    case "source": {
      editSource(content);
      break;
    }
    case "standfirst": {
      editStandfirst(content);
      break;
    }
    default: return;
    }
  }


  updateAxes(type, content, editorState, contentState){
    const {setAxisYLabelChange,  setAxisYLabelRes, labelIndex, isTop, yIndent, setAxisYScale, chartId} = this.props;

    switch (type) {
    case "xLabel": {
      responsiveXLabel();
      break;
    }

    case "yLabel": {
      const changes = { index: labelIndex, label: content };
      setAxisYLabelRes(getAxisYLabelRes());
      setAxisYLabelChange(changes);
      break;
    }

    case "xTexts":
      setTimeout(() => responsiveXTexts(), 10);
      break;

    case "yTexts": {
      // max char limitation with exception of the top tick
      const max = isTop ? 36 : 12;
      if (content.length > max) {
        const contentReplacement = content.slice(0, max);
        const contentStateReplace = this.modifyContentStateReplace(
          editorState,
          contentState,
          content,
          contentReplacement
        );
        editorState = EditorState.push(
          editorState,
          contentStateReplace,
          "insert-characters"
        );
      }

      // res update
      const indent = getAxisYTextWidth(chartId);
      if (indent !== yIndent) {
        setAxisYScale(indent);
      }
      break;
    }
    default: break;
    }
  }

  updateChart(editorState) {
    const {type, text} = this.props;
    const contentState = editorState.getCurrentContent();
    const content = contentState.getPlainText().trim();

    // if blur, prevent second update by input change
    // TODO: debug and remove this weird hack
    if (this.contentReplacement) {
      this.cancelOnChange = true;
      this.contentReplacement = undefined;
    } else if (this.cancelOnChange) {
      this.cancelOnChange = false;
      return;
    }

    const contentHasChanged = text !== content;
    if(contentHasChanged) {
      this.updateFurniture(type, content);
      this.updateAxes(type, content, editorState, contentState);
    }
  }

  onChange(editorState) {
    this.setState({ editorState });
    this.updateChart(editorState);
  }

  /* case 2a: editor out of focus handler */
  onBlur() {
    //console.log(e.target)
    const editorState = this.state.editorState;
    const contentState = editorState.getCurrentContent();
    const content = contentState.getPlainText();
    const isContentEmpty = content.trim() === "";
    const axisType = this.props.type || "";

    this.contentReplacement = undefined;

    /* call by a meta or a text with ticks in the graph */
    if (isContentEmpty && (axisType.indexOf("Text") > -1 || !axisType)) {
      this.contentReplacement = "*";
    } else if (
      /* call by ticks or range in setup 2 */
      axisType.indexOf("Tick") > -1 ||
      axisType.indexOf("Range") > -1
    ) {
      const { axis, dataRanges, dateFormat } = this.props;
      const axisTypeData = axis[axisType[0]];
      const isDate = axisTypeData.edits ? true : false;

      let axisTarget = axisType.slice(1, axisType.length).toLowerCase();
      let contentOld = isDate
        ? axisTypeData.edits[axisTarget].join(", ")
        : axisTypeData[axisTarget].join(", ");

      // content no change
      if (content === contentOld) return;

      // content changed
      // data preprocess: input str -> arr -> sort (number) -> remove duplicates
      let dataTarget = isDate
        ? uniqueArray(content.split(",").map(d => d.trim()))
        : uniqueArray(
          content
            .split(",")
            .map(d => parseFloat(d))
            .sort((n1, n2) => n1 - n2)
        );
      let dataTargetExtra = null;
      let contentNew = dataTarget.join(", ");

      // update ticks or range if vaild
      // replace content with new/old content if changed
      if (isDate) {
        let dataParsed;
        //console.log(axisTypeData.edits)

        switch (axisTypeData.edits.value) {
        case "index":
          dataParsed = dataTarget.map(txt => ({
            txt,
            val: axisTypeData.edits.dates.findIndex(d => txt === d)
          }));
          // Note: for tick texts that can't be indexed, index returns -1
          // this change is then not valid and will fail the validation step
          break;
        case "number":
          dataParsed = getDateScaleValues(
            dataTarget,
            dateFormat,
            false,
            true
          ).map((val, i) => ({ txt: dataTarget[i], val }));
          break;
        case "timestamp": {
          const parser = d3.timeParse(dateFormat);
          dataParsed = dataTarget.map(txt => ({
            txt,
            val: parser(txt) || new Date(txt)
          }));
          break;
        }
        default:
          //console.log("add new case")
        }
        dataParsed.sort((n1, n2) => n1.val - n2.val);
        dataTarget = dataParsed.map(d => d.val);
        dataTargetExtra = dataParsed.map(d => d.txt);
        contentNew = dataTargetExtra.join(", ");
      }

      const validation = validate(
        axisTarget,
        dataTarget,
        axisTypeData.range,
        dataRanges[axisType[0]]
      );
      if (validation) {
        // TODO: update edits.ticks !? <= contentOld issue
        this.props.setAxisDataOnTypes(
          axisType[0],
          axisTarget,
          dataTarget,
          dataTargetExtra
        );

        const atomAxisData = { [axisType[0]]: { [axisTarget]: dataTarget} };
        this.props.setAtomAxisData(atomAxisData);

        this.contentReplacement =
          contentNew !== content ? contentNew : undefined;
      } else {
        this.contentReplacement = contentOld;
      }
    }

    let newEditorState = editorState;
    if (this.contentReplacement) {
      const style = this.props.bold ? "BOLD" : "";
      const contentStateReplace = this.modifyContentStateReplace(
        editorState,
        contentState,
        content,
        this.contentReplacement,
        style
      );
      newEditorState = EditorState.push(
        editorState,
        contentStateReplace,
        "insert-characters"
      );
      // TODO: blur after text replacement ?
    }

    this.onChange(newEditorState, "blur");
    return "handled";
  }

  /* case 2b: enter/return key handler for
  /* 1. single line editing only
  /* 2. setting cursor out of focus */
  handleReturn(e) {
    e.target.blur();
    return "handled";
  }

  /* case 3: shortcut keys handler
  /* RichUtils has information about the core key commands available to web editors,
  /* such as Cmd+B (bold), Cmd+I (italic), and so on. */
  handleKeyCommand(command) {
    const newEditorState = RichUtils.handleKeyCommand(
      this.state.editorState,
      command
    );
    if (newEditorState) {
      this.onChange(newEditorState, "key");
      return "handled";
    }
    return "not-handled";
  }

  render() {
    return (
      <Editor
        ref="editor"
        editorState={this.state.editorState}
        onChange={this.onChange.bind(this)}
        handleKeyCommand={this.handleKeyCommand.bind(this)}
        handleReturn={this.handleReturn.bind(this)}
        onBlur={this.onBlur.bind(this)}
        spellCheck={true}
        stripPastedStyles={true}
      />
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(InlineEditor);

function validate(axisType, input, axisRange, dataRange) {
  switch (axisType) {
  case "ticks":
    return (
      input.length > 0 &&
        input[0] >= axisRange[0] &&
        input[input.length - 1] <= axisRange[1]
    );
  case "range":
    return (
      input.length === 2 &&
        input[0] <= dataRange[0] &&
        input[1] >= dataRange[1]
    );
  default:
    console.warn("validation not available!");
  }
}
