// In future, we may store some of these chart properties on the CAPI Atom model.
// See https://github.com/guardian/content-atom
// For now we are parsing the atom's defaultHtml to extract them when the chart atom is loaded

import {colChart, barChart, lineChart, stackedColChart, stackedBarChart} from "./chartConfig";

const extractColor = (styleString, propertyName) => {
  const propertyArr = styleString.split(";");
  const color = propertyArr.find(prop => prop.includes(propertyName));
  const actualColor = color.split(":")[1].trim();
  return actualColor;
};

const extractText = (elArr) => {
  return elArr.reduce((acc, curr) => {
    const txt = curr.querySelector("[data-text=true]").textContent;
    return [...acc, txt];
  }, []);
};

// Create fake wrapper element to access and manipulate the html blob
const createChartBox = htmlBlob => {
  let el = document.createElement("html");
  el.innerHTML = htmlBlob;
  return el.querySelector(".js-graph");
};

// Extracts label styles for bar labels, column labels and legend labels
const getLabelStyles = (chartBox, chartConfig) => {
  const {labelBarY, labelColX, labelLegend} = chartConfig;

  const allSelectors = [labelBarY, labelColX, labelLegend].filter(s => s !== null);
  const extractStyleArr = (styleString) => styleString.split(";").filter(i => i.trim());

  const extractStyleFromLabelElements = (els) => {
    return els.reduce((acc, el) => {
      const styleString = el.firstChild.getAttribute("style");
      const styleArr = styleString ? extractStyleArr(styleString) : null;
      return [...acc, styleArr];
    }, []);
  };
  // this should return an object with keyed arrays eg: { 'label-legend': [..], 'atom-column-label: [..]}
  return allSelectors.reduce((acc, selector) => {
    const labels = Array.from(chartBox.querySelectorAll(`.${selector}`));
    const styleArr = extractStyleFromLabelElements(labels);
    acc[selector.toString()] = styleArr;
    return acc;
  }, {});

};

const getChartColors = (chartBox, chartConfig) => {
  const {colorAttr, colorClass} = chartConfig;
  const elements = Array.from(chartBox.querySelectorAll(`.${colorClass}`));
  return elements.reduce((acc,curr) => {
    const color = extractColor(curr.getAttribute("style"), colorAttr);
    return [...acc, color];
  }, []);
};

const getAxisTicks = (chartBox, attrName) => {
  if(!attrName) return null;
  const stateHolderDiv = chartBox.querySelector("#state-holder");
  return stateHolderDiv.getAttribute(attrName).split(",");
};

const getTexts = (chartBox, textClass) => {
  if(!textClass) return null;
  const textContainerEls = Array.from(chartBox.querySelectorAll(`.${textClass}`));
  return extractText(textContainerEls);
};

const getAxes = (chartBox, config) => {

  const {axisXTicksClass, axisYTicksClass, axisXTextClass, axisYTextClass, labelColX, labelBarY} = config;

  const xTicks = getAxisTicks(chartBox, axisXTicksClass);
  const yTicks = getAxisTicks(chartBox, axisYTicksClass);
  const xTexts = getTexts(chartBox, axisXTextClass);
  const yTexts = getTexts(chartBox, axisYTextClass);
  const xColLabels = getTexts(chartBox, labelColX);
  const yBarLabels = getTexts(chartBox, labelBarY);

  return ({
    x: {
      ticks: xTicks,
      texts: xTexts,
      colLabels: xColLabels
    },
    y: {
      ticks: yTicks,
      texts: yTexts,
      barLabels: yBarLabels
    }
  });
};

const parseHtml = (chartType, htmlBlob) => {
  const chartBox = createChartBox(htmlBlob);

  switch (chartType) {

  case "BAR": {
    const config = barChart;
    return {
      colors: getChartColors(chartBox, config),
      axes: getAxes(chartBox, config),
      labelStyling: getLabelStyles(chartBox, config)
    };
  }

  case "BARSTACK":
  case "BARSTACK100": {
    const config = stackedBarChart;
    return {
      colors: getChartColors(chartBox, config),
      axes: getAxes(chartBox, config),
      labelStyling: getLabelStyles(chartBox, config)
    };
  }

  case "COL": {
    const config = colChart;
    return {
      colors: getChartColors(chartBox, config),
      axes: getAxes(chartBox, config),
      labelStyling: getLabelStyles(chartBox, config)
    };
  }

  case "COLSTACK": {
    const config = stackedColChart;
    return {
      colors: getChartColors(chartBox, config),
      axes: getAxes(chartBox, config),
      labelStyling: getLabelStyles(chartBox, config)
    };
  }

  case "LINE":
  case "LINEDISCRETE": {
    const config = lineChart;
    return {
      colors: getChartColors(chartBox, config),
      axes: getAxes(chartBox, config),
      labelStyling: getLabelStyles(chartBox, config)  
    };
  }

  default:
    return null;
  }
};

export { parseHtml };
