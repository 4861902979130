import React from "react";
import { connect } from "react-redux";
import ComponentEditor from "../section4Panel/Editor";
import {barChart} from "../../data/chartConfig";

const mapStateToProps = state => ({
  isInline: !state.dataChart.string1IsRes,
  labelWidth: state.dataChart.string1Width
});


class Row extends React.Component {
  render() {
    const { isInline, isLabel, label, labelWidth, labelStyle, labelIndex, key } = this.props;

    // TODO: any other styles?
    const isBold =
      labelStyle && labelStyle.find(prop => prop.includes("font-weight: bold"));
    const isItalic =
      labelStyle &&
      labelStyle.find(prop => prop.includes("font-style: italic"));

    const labelComponent = isLabel ? (
      <div
        className={`label ${barChart.labelBarY}`}
        style={{
          display: "inline-block",
          width: labelWidth
        }}
      >
        <ComponentEditor
          text={label}
          type="yLabel"
          bold={isBold}
          italic={isItalic}
          key={key}
          labelIndex={labelIndex}
        />
      </div>
    ) : null;

    const groupComponent = (
      <div
        className="group"
        style={{
          flex: isInline && isLabel ? "1" : "auto",
          position: "relative"
        }}
      >
        <div className="grid"></div>
        <div className="bars"></div>
      </div>
    );

    return (
      <div
        className="row"
        style={{
          display: isInline && isLabel ? "flex" : "block",
          height: isInline ? "24px" : "auto"
        }}
      >
        {labelComponent}
        {groupComponent}
      </div>
    );
  }
}

export default connect(mapStateToProps)(Row);
