import React from "react";
import {connect} from "react-redux";
import {d3} from "../../lib/d3-lite";
import {appendChartData} from "../../actions";
import {getDomainByDataRange} from "../../data/calcScaleDomain";
import ComponentRow from "./BarBase";
import drawChart from "./bar";
import {getAtomProperty, getAtomAxisProperty, getAtomLabelProperty} from "../../lib/atom/helpers";
import {stackedBarChart} from "../../data/chartConfig";

const mapStateToProps = (state) => ({
  data: state.dataChart,
  colors: state.dataSetup.colors,
  atom: state.atom
});

const mapDispatchToProps = (dispatch) => ({
  onSelect: (keys, scale) => dispatch(appendChartData(keys, scale))
});


class BarStack extends React.Component {

  componentDidMount() {
    this.renderChart();
  }
  componentDidUpdate() {
    this.renderChart();
  }

  render() {
    const {data, onSelect, callByStep, atom} = this.props;

    const setChartData = () => {
      if (callByStep === 3) { onSelect(data.keys, this.scale); }
    };

    const atomLabelStyles = getAtomLabelProperty(atom, stackedBarChart.labelBarY);
    const atomLabels = getAtomAxisProperty(atom, "y", "barLabels");
    const labels = atomLabels ? atomLabels : data.string1Col;

    const isLabel = callByStep === 4;
    return (
      <div className="canvas" ref="div" onClick={setChartData}>
        {labels.map((label, i) =>
          <ComponentRow
            isLabel={isLabel}
            label={label}
            key={i}
            labelIndex={i}
            labelStyle={atomLabelStyles ? atomLabelStyles[i] : null} />
        )}
      </div>
    );
  }

  renderChart() {

    /* data */
    const {data, callByStep, atom} = this.props;
    const numberRows = data.numberRows;
    const numberRowSums = numberRows.map(ns => ns.reduce((n1, n2) => n1 + n2));

    const atomColors = getAtomProperty(atom, "chartColors");
    const colors = atomColors ? atomColors : this.props.colors;

    // scale
    this.scale = {};
    this.scale.x = d3.scaleLinear()
      .domain(getDomainByDataRange(numberRowSums))
      .range([0, 100]);

    // chart
    const dataChart = numberRows.map((numRow, i) => ({
      value: numRow.map((num, j) => ({
        title: num,
        width: Math.abs(this.scale.x(num) - this.scale.x(0)),
        shift: (num < 0 && j===0) ? this.scale.x(numberRowSums[i]) : null
      }))
    }));


    /* draw */
    drawChart(this.refs, dataChart, {display: "inline-block", colors, callByStep});
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BarStack);
