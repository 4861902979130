import {d3} from "../../lib/d3-lite";
import {getAtomAxisProperty} from "../../lib/atom/helpers";
import {default_metaText} from "../../data/config";

export const formatTicks = (dateFormat, ticks) => {
  const formatTime = d3.timeFormat(dateFormat);
  const formattedTicks = ticks.map(tick => {
    if (typeof tick === "string" && tick.includes("/")) {
      const splitDate = tick.split("/");
      const year = splitDate[2] ? splitDate[2] : ticks[0].split("/")[2];
      return formatTime(new Date(year, splitDate[1] - 1,splitDate[0]));
    } else {
      return formatTime(new Date(tick));
    }
  });
  return formattedTicks.join(", ");
};

export const getTicks = (atom, defaultAxis, type) => {

  const atomTicks = getAtomAxisProperty(atom, type, "ticks");

  if (atomTicks) {
    return atomTicks;
  }
  else if (defaultAxis.edits) {
    return defaultAxis.edits.ticks;
  }
  else {
    return defaultAxis.ticks;
  }
};

export const getAxisProperty = (axis, axisType, property) => axis && axis[axisType] ? axis[axisType][property] : [];

// We want to always prepend 'Guardian graphic | Source: ' to the source
export const getSourceText = (source) => {
  const existingIncludesDefault = source && source.includes("Guardian graphic") && source.includes("Source");
  if(!existingIncludesDefault) {
    return `${default_metaText["source"]}: ${source ? source : ""}`;
  } else {
    return (source || default_metaText["source"] + ": ");
  }
};

export const getMetaText = (type, metaData) => {
  if(type === "source" && metaData[type]) {
    return getSourceText(metaData["source"]);
  } else {
    return (metaData[type] || default_metaText[type]);
  }
};
