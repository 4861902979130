// allow visually hidden link pattern - missing attributes are added when needed
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */

import React from "react";
import { connect } from "react-redux";
import "./section5Embed.css";

import downloadFile from "react-file-download";
import htmlTemplate from "./charts/template";
import AtomApi from "../lib/atom/api";
import Config from "../lib/config";
import { postToS3Uploader } from "../lib/s3-client";
import { atomChartList } from "./charts";

const STEP = 5;

const mapStateToProps = state => ({
  stepActive: state.stepActive,
  atom: state.atom,
  chartId: state.chartId
});

class Section extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      showEmbedMsg: false
    };
  }

  embed() {
    const data = getHTMLFileData();
    this.setState({ showEmbedMsg: true });
    postToS3Uploader(data);
  }

  downloadHTML() {
    const data = getHTMLFileData();

    // save html as a local file
    const timestamp = Date.now();
    const filename = timestamp + "-chart-" + data.id + ".html";
    downloadFile(data.html, filename);
  }

  updateAtom() {
    const data = getHTMLFileData();

    const { atom } = this.props;
    const { id } = atom;

    AtomApi.updateAtom(id, atom, data.html).then(() => {
      window.parent.postMessage("saving_atom", "*");
    });
  }

  renderSaveAtomButton() {
    const atomSupportedCharts = Object.keys(atomChartList);

    if (Config.isEmbedded && atomSupportedCharts.includes(this.props.chartId)) {
      return (
        <input
          type="button"
          className="button"
          value="Save Atom"
          onClick={() => this.updateAtom()}
        />
      );
    }
  }

  componentDidUpdate(prevProps){

    // when step changes - ie user changes the chart, hide the embed link until user requests a new one
    if(prevProps.stepActive !== this.props.stepActive) {
      this.setState({ showEmbedMsg: false });
    }
  }

  render() {
    const { stepActive } = this.props;
    return (
      <div
        className={"section" + (stepActive >= STEP ? "" : " d-n")}
        id="section5"
      >
        <h1>5. Download or embed:</h1>
        <input
          type="button"
          className={"button"}
          value="embed"
          onClick={this.embed.bind(this)}
        />
        <input
          type="button"
          className="button"
          value="download HTML"
          onClick={this.downloadHTML.bind(this)}
        />
        {this.renderSaveAtomButton()}
        <div className={ this.state.showEmbedMsg ? "link js-link": "v-h link js-link"}>
          <span id="embed-message">link: </span>
          <a target="_blank"></a>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps)(Section);

function getHTMLFileData() {
  const elGraph = document.querySelector("#section4 .js-graph");
  const chartId = elGraph.querySelector(".js-chart").dataset.id;
  const elGraphCopy = elGraph.cloneNode(true);
  elGraphCopy.style.width = "100%";

  // remove editors' elements created by Draft.js and
  // replace them with content only (a list of spans)
  const elEditors = [...elGraphCopy.querySelectorAll(".DraftEditor-root")];
  elEditors.forEach(elEditor => {
    const elParent = elEditor.parentNode;
    const elContent = elEditor.querySelector("span").parentNode.innerHTML;
    elParent.removeChild(elEditor);
    elParent.innerHTML = elContent;

    // TODO: remove data attributes
    //...
  });

  const htmlGraph = elGraphCopy.outerHTML;
  return {
    id: chartId,
    html: htmlTemplate(htmlGraph)
  };
}
