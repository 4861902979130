import React from "react";
import "./atomBanner.css";
import Config from "../lib/config";

export default class AtomBanner extends React.Component {
  render() {
    return (
      <section className="banner">
        <p>Charts can be made as atoms allowing them to be re-edited.
        Currently only <code> line </code> charts work.
        </p>
        <p>
          <a href={`${Config.atomWorkshopDomain}/create`} target="_blank" rel="noopener noreferrer">
            Create a Chart Atom here
          </a>
        </p>
      </section>
    );
  }
}
