import React from "react";
import {connect} from "react-redux";
import ComponentEditor from "./Editor";
import { getAtomLabelProperty, getAtomAxisProperty } from "../../lib/atom/helpers";
import {colChart} from "../../data/chartConfig";


const mapStateToProps = (state) => ({
  id: state.chartId,
  indent: state.dataChart.indent,
  defaultLabels: state.dataChart.rowGroup,
  atom: state.atom
});

class LabelX extends React.Component {


  render() {
    const {indent, atom, defaultLabels} = this.props;

    const labels = getAtomAxisProperty(atom, "x", "colLabels") || defaultLabels;
    const labelStyles = getAtomLabelProperty(atom, colChart.labelColX);

    const length = labels.length;
    const padding = 2;
    const band = (100 - padding*(length-1)) / length;

    /* draw */
    const drawAxisTexts = labels.map((label, i) => {
      const isBold = labelStyles && labelStyles[i] && labelStyles[i].includes("font-weight: bold");
      const isItalic = labelStyles && labelStyles[i] && labelStyles[i].includes("font-style: italic");

      return <div key={"text" + i}
        className={`label ${colChart.labelColX}`}
        style={{
          position: "absolute",
          top: "8px",
          left: (band + padding) * i + "%",
          width: band + "%",
          lineHeight: "14px",
          paddingTop: "2px",
          textAlign: "left",
          fontWeight: isBold ? "bold" : "normal",
          fontStyle: isItalic ? "italic" : "normal"
        }}
      >
        <ComponentEditor text={label} type="xLabel" />
      </div>;
    });

    return (
      <div className="label-x"
        data-x-bottom={true}
        data-y-indent={indent}
        style={{
          position: "absolute",
          top: "calc(100% - 1px)", // due to svg padding: 1px
          right: 0,
          width: "calc(100% - " + indent + "px)",
        }}>
        {drawAxisTexts}
      </div>
    );
  }
}

export default connect(mapStateToProps)(LabelX);
