import React from "react";
import {connect} from "react-redux";
import {dropColorTo} from "../../actions";
import {updateAtomColors} from "../../actions/atomActions";
import ComponentEditor from "./Editor";
import {getAtomProperty, getAtomLabelProperty} from "../../lib/atom/helpers";
import { labelLegend } from "../../data/chartConfig";

const mapStateToProps = (state) => ({
  id: state.chartId,
  dataSetup: state.dataSetup,
  legend: state.dataChart.legend,
  dataChart: state.dataChart,
  atom: state.atom
});

const mapDispatchToProps = (dispatch) => ({
  onDropColor: (index, color) => {
    dispatch(dropColorTo(index));
    dispatch(updateAtomColors(index, color));
  }
});


class Legend extends React.Component {

  render() {
    const {id, dataChart, dataSetup, onDropColor, isBarBased, atom} = this.props;
    const {legend, string1Width} = dataChart;

    const length = legend.length;
    const defaultColors = dataSetup.colors;
    const display = dataSetup.display.legend;

    const labelColors = getAtomProperty(atom, "chartColors") || defaultColors;
    const labelStyles = getAtomLabelProperty(atom, labelLegend);

    const chartWidth = dataSetup.size.w || 300;
    const labelWidth = string1Width;
    const marginLeft = isBarBased && !id.includes("broken") && (labelWidth <= chartWidth/3) ? labelWidth : 0;

    let drawLegend = null;

    if (length === 1) {
      drawLegend = <ComponentEditor text={legend[0]} />;
    }
    else if (length > 1) {
      drawLegend = legend.map((label, seriesIndex) => {
        let isBold = labelStyles && labelStyles[seriesIndex] && labelStyles[seriesIndex].find(prop => prop.includes("font-weight: bold"));
        let isItalic = labelStyles && labelStyles[seriesIndex] && labelStyles[seriesIndex].find(prop => prop.includes("font-style: italic"));

        return (
          <div className="legend-item" key={seriesIndex}>
            <span className="legend-color"
              style={{backgroundColor: labelColors[seriesIndex]}}
              onDoubleClick={()=>onDropColor(seriesIndex, dataSetup.pickColor)}>
            </span>

            <span className="legend-label">
              <ComponentEditor type="legend" text={label} bold={isBold} italic={isItalic} legendIndex={seriesIndex}/>
            </span>
          </div>
        );
      });
    }

    return (
      <div className={"legend" + (display ? "" : " d-n")} style={{marginLeft: marginLeft}}>{drawLegend}</div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Legend);
