export const getAtomProperty = (atom, property) => {
  return atom &&
    atom.data &&
    atom.data.chart &&
    atom.data.chart[property];
};

export const getAtomLabelProperty = (atom, property) => {
  const labelStyles = getAtomProperty(atom, "labelStyles");
  return labelStyles && labelStyles[property];
};

export const getAtomAxisProperty = (atom, type, property) => {
  return atom &&
    atom.data &&
    atom.data.chart &&
    atom.data.chart.axis &&
    atom.data.chart.axis[type] &&
    atom.data.chart.axis[type][property];
};
