import {
  ATOM_GET_RECEIVED,
  UPDATE_ATOM_TABULAR_DATA,
  UPDATE_ATOM_FURNITURE,
  UPDATE_ATOM_HIDDEN_COLUMNS,
  UPDATE_ATOM_HIDDEN_ROWS,
  UPDATE_ATOM_CHART_TYPE,
  UPDATE_ATOM_DISPLAY_SETTINGS,
  UPDATE_ATOM_DISPLAY_SETTING,
  UPDATE_ATOM_HEADLINE,
  UPDATE_ATOM_LEGEND,
  UPDATE_ATOM_SOURCE,
  UPDATE_ATOM_STANDFIRST,
  UPDATE_ATOM_COLORS,
  LOAD_INPUT_FROM_ATOM,
  IMPORT_CHART_COLORS,
  IMPORT_LABEL_STYLING,
  SET_ATOM_AXIS_TICKS,
  SET_ATOM_HAS_BEEN_EDITED
} from "../actions/atomActions";
import Config from "../lib/config";

export function atomInput(atomInput = null, action) {
  switch (action.type) {
  case LOAD_INPUT_FROM_ATOM:
    return action.atomInput;
  default:
    return atomInput;
  }
}

export function atom(atom = {}, action) {
  // we're not embedded, ignore atom based updates
  if (!Config.isEmbedded) {
    return atom;
  }

  switch (action.type) {
  case ATOM_GET_RECEIVED:
    return action.atom;

  case UPDATE_ATOM_TABULAR_DATA:
  case UPDATE_ATOM_FURNITURE:
  case UPDATE_ATOM_HIDDEN_COLUMNS:
  case UPDATE_ATOM_HIDDEN_ROWS:
  case UPDATE_ATOM_DISPLAY_SETTINGS: {
    return {
      ...atom,
      data: {
        ...atom.data,
        chart: {
          ...(atom.data || {}).chart,
          ...action.data.chart
        }
      }
    };
  }

  case UPDATE_ATOM_CHART_TYPE: {
    const { chartType } = action.data.chart;
    return {
      ...atom,
      data: {
        ...atom.data,
        chart: {
          ...(atom.data || {}).chart,
          chartType
        }
      }
    };
  }

  case UPDATE_ATOM_DISPLAY_SETTING:
    return {
      ...atom,
      data: {
        ...atom.data,
        chart: {
          ...atom.data.chart,
          displaySettings: {
            ...atom.data.chart.displaySettings,
            [action.field]: !atom.data.chart.displaySettings[action.field]
          }
        }
      }
    };
  case UPDATE_ATOM_HEADLINE:
    return {
      ...atom,
      data: {
        ...atom.data,
        chart: {
          ...atom.data.chart,
          furniture: {
            ...atom.data.chart.furniture,
            headline: action.headline
          }
        }
      }
    };
  case UPDATE_ATOM_SOURCE:
    return {
      ...atom,
      data: {
        ...atom.data,
        chart: {
          ...atom.data.chart,
          furniture: {
            ...atom.data.chart.furniture,
            source: action.source
          }
        }
      }
    };
  case UPDATE_ATOM_STANDFIRST:
    return {
      ...atom,
      data: {
        ...atom.data,
        chart: {
          ...atom.data.chart,
          furniture: {
            ...atom.data.chart.furniture,
            standfirst: action.standfirst
          }
        }
      }
    };
  case UPDATE_ATOM_LEGEND: {
    const updatingColumn = action.index + 1;
    const { columnHeaders } = atom.data.chart.tabularData;
    const updatedColumnHeaders = Array.from(columnHeaders);
    updatedColumnHeaders[updatingColumn] = action.value;

    return {
      ...atom,
      data: {
        ...atom.data,
        chart: {
          ...atom.data.chart,
          tabularData: {
            ...atom.data.chart.tabularData,
            columnHeaders: updatedColumnHeaders
          }
        }
      }
    };
  }
  case IMPORT_CHART_COLORS: {
    const updatedChartColors = action.chartColors;

    return {
      ...atom,
      data: {
        ...atom.data,
        chart: {
          ...atom.data.chart,
          chartColors: updatedChartColors
        }
      }
    };
  }
  case UPDATE_ATOM_COLORS: {
    const newColor = action.color.hexCode;
    const seriesIndex = action.color.index;
    const { chartColors } = atom.data.chart;

    let newChartColors = chartColors;

    if(chartColors) { newChartColors[seriesIndex] = newColor; }

    return {
      ...atom,
      data: {
        ...atom.data,
        chart: {
          ...atom.data.chart,
          chartColors: newChartColors
        }
      }
    };
  }
  case IMPORT_LABEL_STYLING: {
    const updatedLabelStyling = action.labelStyles;

    return {
      ...atom,
      data: {
        ...atom.data,
        chart: {
          ...atom.data.chart,
          labelStyles: updatedLabelStyling
        }
      }
    };
  }
  case SET_ATOM_AXIS_TICKS: {
    const ticks = action.ticks;
    return {
      ...atom,
      data: {
        ...atom.data,
        chart: {
          ...atom.data.chart,
          axis: {
            ...atom.data.chart.axis,
            ...ticks
          }
        }
      }
    };
  }
  case SET_ATOM_HAS_BEEN_EDITED: {
    const {hasUserSelectedChartType} = action;
    return {
      ...atom,
      data: {
        ...atom.data,
        chart: {
          hasUserSelectedChartType,
          ...atom.data.chart,
        }
      }
    };
  }

  default:
    return atom;
  }
}
