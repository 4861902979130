// When parsing the html of charts to load data about them, this file is helpful for
// summarising what data needs to be taken out of the html for each chart type, and the class name
// of the element where that data can be found.

// Standard strings
const labelLegend = "legend-label";



// Chart types

// BAR CHART
const barChart = {
  colorClass: "atom-bar-color",
  colorAttr: "background-color",
  axisYTextClass: null,
  axisXTextClass: "axis-x-text",
  axisXTicksClass: "data-x-ticks",
  axisYTicksClass: null,
  labelColX: null,
  labelBarY: "atom-bar-label",
  labelLegend: null
};

// COLUMN CHART
const colChart = {
  colorClass: "atom-column-color",
  colorAttr: "fill",
  axisYTextClass: "axis-y-text",
  axisXTextClass: null,
  axisXTicksClass: null,
  axisYTicksClass: "data-y-ticks",
  labelColX: "atom-column-label",
  labelBarY: null,
  labelLegend: null
};

// STACKED COLUMN CHART
const stackedColChart = {
  colorClass: "atom-stacked-column-color",
  colorAttr: "fill",
  axisYTextClass: "axis-y-text",
  axisXTextClass: null,
  axisXTicksClass: null,
  axisYTicksClass: "data-y-ticks",
  labelColX: "atom-column-label",
  labelBarY: null,
  labelLegend: labelLegend
};

// STACKED & PERCENTAGE BAR CHARTS
const stackedBarChart = {
  colorClass: "atom-bar-color",
  colorAttr: "background-color",
  axisYTextClass: null,
  axisXTextClass: "axis-x-text",
  axisXTicksClass: "data-x-ticks",
  axisYTicksClass: null,
  labelColX: null,
  labelBarY: "atom-bar-label",
  labelLegend: labelLegend
};

// LINE CHART
const lineChart = {
  colorClass: "atom-line-color",
  colorAttr: "stroke",
  axisYTextClass: "axis-y-text",
  axisXTextClass: "axis-x-text",
  axisXTicksClass: "data-x-ticks",
  axisYTicksClass: "data-y-ticks",
  labelColX: null,
  labelBarY: null,
  labelLegend: labelLegend
};




export {colChart, barChart, lineChart, stackedColChart, stackedBarChart, labelLegend};
