import React from "react";
import { connect } from "react-redux";
import { d3 } from "../../lib/d3-lite";
import { uniqueArray } from "../../lib/array";
import { appendChartData } from "../../actions";
import { getDomainByDataRange } from "../../data/calcScaleDomain";
import ComponentRow from "./BarBase";
import drawChart from "./bar";
import {getAtomProperty, getAtomAxisProperty, getAtomLabelProperty} from "../../lib/atom/helpers";
import {barChart} from "../../data/chartConfig";

const mapStateToProps = state => ({
  data: state.dataChart,
  colors: state.dataSetup.colors,
  atom: state.atom
});

const mapDispatchToProps = dispatch => ({
  onSelect: (keys, scale) => dispatch(appendChartData(keys, scale))
});

class Bars extends React.Component {
  componentDidMount() {
    this.renderChart();
  }
  componentDidUpdate() {
    this.renderChart();
  }

  render() {
    const { data, onSelect, callByStep, atom } = this.props;

    const setChartData = () => {
      if (callByStep === 3) {
        const legendKeys =
          this.colorKeys.length !== 0 ? this.colorKeys : data.keys;
        onSelect(legendKeys, this.scale);
      }
    };
    const atomLabels = getAtomAxisProperty(atom, "y", "barLabels");
    const labels = atomLabels ? atomLabels : data.string1Col;
    const atomLabelStyles = getAtomLabelProperty(atom, barChart.labelBarY);

    const isLabel = callByStep === 4;
    return (
      <div className="canvas" ref="div" onClick={setChartData}>
        {labels.map((label, i) => {
          return (
            <ComponentRow
              isLabel={isLabel}
              label={label}
              key={i}
              labelStyle={atomLabelStyles ? atomLabelStyles[i] : null}
              labelIndex={i}
            />
          );
        })}
      </div>
    );
  }

  renderChart() {
    /* data */
    const { data, colors, callByStep, atom } = this.props;
    const numberRows = data.numberRows;
    const colorGroup = data.string2Col;
    this.colorKeys = uniqueArray(colorGroup);

    // scale
    this.scale = {};
    this.scale.x = d3
      .scaleLinear()
      .domain(getDomainByDataRange(data.numbers))
      .range([0, 100]);

    const scaleColors = d3
      .scaleOrdinal()
      .domain(this.colorKeys)
      .range(colors);

    const barColors = getAtomProperty(atom, "chartColors");

    // chart
    const dataChart = numberRows.map((numRow, i) => ({
      value: numRow.map(num => ({
        title: num,
        width: Math.abs(this.scale.x(num) - this.scale.x(0)),
        shift: num > 0 ? this.scale.x(0) : this.scale.x(num),
        color: colorGroup.length !== 0 ? scaleColors(colorGroup[i]) : null,
        barColor: barColors ? barColors[i] : null
      }))
    }));

    /* draw */
    const getBarHeight = count =>
      Math.round((((24 - (count - 1)) / 3) * 2) / count);
    const barHeight = getBarHeight(numberRows[0].length);

    drawChart(this.refs, dataChart, { barHeight, colors, callByStep });
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Bars);
