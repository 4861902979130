import queryString from "query-string";

const visualsDomains = {
  PROD: "charts.gutools.co.uk",
  CODE: "charts.code.dev-gutools.co.uk",
  DEV:  "charts.local.dev-gutools.co.uk"
};

const atomWorkshopDomains = {
  PROD: "atomworkshop.gutools.co.uk",
  CODE: "atomworkshop.code.dev-gutools.co.uk",
  DEV:  "atomworkshop.local.dev-gutools.co.uk"
};

const s3UploaderDomains = {
  PROD: "s3-uploader.gutools.co.uk",
  CODE: "s3-uploader.code.dev-gutools.co.uk",
  DEV:  "s3-uploader.local.dev-gutools.co.uk"
};

const loginDomains = {
  PROD: `login.gutools.co.uk/login?returnUrl=https://${visualsDomains.PROD}`,
  CODE: `login.code.dev-gutools.co.uk/login?returnUrl=https://${visualsDomains.CODE}`,
  DEV: `login.local.dev-gutools.co.uk/login?returnUrl=https://${visualsDomains.DEV}`
};

export default class Config {
  static get stage() {
    switch (window.location.hostname) {
    case visualsDomains.PROD:
      return "PROD";
    case visualsDomains.CODE:
      return "CODE";
    default:
      return "DEV";
    }
  }

  static get isProd() {
    return Config.stage === "PROD";
  }

  static get isCode() {
    return Config.stage === "CODE";
  }

  static get isDev() {
    return Config.stage === "DEV";
  }

  static get atomWorkshopDomain() {
    return `https://${atomWorkshopDomains[Config.stage]}`;
  }

  static get s3UploaderDomain() {
    return `https://${s3UploaderDomains[Config.stage]}`;
  }

  static get loginDomain() {
    return `https://${loginDomains[Config.stage]}`;
  }

  static get embeddedAtomId() {
    return queryString.parse(window.location.search).atom;
  }

  static get isEmbedded() {
    return !!Config.embeddedAtomId;
  }

}

