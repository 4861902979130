import {d3} from "../../lib/d3-lite";
import {isHighlight, dropColorToHighlight} from "../section4Panel/paletteDropColorHack";
import {colChart, stackedColChart} from "../../data/chartConfig";

export default function col(els, dataChart, opt = {}) {
  const isStack = opt.id.includes("Stack");

  // HACK: for highlight event
  const isOneColor = isHighlight(opt.callByStep);
  if (isOneColor) { dataChart.map((d, i) => {d.value[0].index = i; return d;}); }

  const colorToUse = (d, alternative) => d.color ? d.color : alternative;

  const getClassString = (d) => [(isOneColor && "c-d c" + d.index), colChart.colorClass].join(" ");

  d3.select(els.svg)
    .html("")
  // group
    .selectAll(".group")
    .data(dataChart)
    .enter().append("g")
    .attr("class", `group ${stackedColChart.colorClass}`)
    .attr("transform", d => {if (d.transform) return d.transform;})
    .style("fill", d => d.color)
    .selectAll("rect")
  // cols
    .data(d => d.value)
    .enter().append("rect")
    .attr("x", d => d.group)
    .attr("y", d => d.shift)
    .attr("width", opt.width)
    .attr("height", d => d.length)
  // col styles on chart type
    .style(colChart.colorAttr, (d, i) => { if(!isStack) return colorToUse(d, opt.colors[i]);} )
  // HACK: color highlight
    .attr("class", d => getClassString(d))
    .on("click", (d) => { if (isOneColor) {dropColorToHighlight(d.index, "fill");} })
  // title tooltip
    .append("title")
    .text(d => d.title);
}

